// Import jquery
// import 'jquery';

// Import bootstrap
import 'bootstrap';

// Import cookieconsent
import 'cookieconsent';

// Import custom app scripts
import './app';
