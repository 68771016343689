/*
 *
 * Application scripts: app.js
 * 2021 © Kalifornijos kryptis, UAB
 * www.feeria.lt
 *
 */

jQuery(document).ready(function($) {
  // Localized object
  // console.log(object_name.templateUrl);

  // Hamburgers
  $('.navbar-toggler').on('click', function() {

    if ( ! $('.navbar-toggler').attr('aria-expanded') == true ) {
      $('.hamburger').removeClass('is-active');
    }

    if ( $('.navbar-toggler').hasClass('collapsed') ) {
      $('.hamburger').removeClass('is-active');
    } else {
      $('.hamburger').addClass('is-active');
    }

  });

  // Scrollable header with animation
  if ($('.js--smart-scroll').length > 0) {
      var last_scroll_top = 0;

      $(window).on('scroll', function() {

          // Get scroll position
          var navbar        = $('.navbar');
          var scroll_top    = $(this).scrollTop();
          var navbar_height = $(navbar).outerHeight();

          // Show or hide menu depends on direction
          if(scroll_top < last_scroll_top) {

              $('.js--smart-scroll').removeClass('scrolled-down')
                                    .addClass('scrolled-up navbar-position-fixed');

          }
          else {

            if ( (scroll_top > navbar_height / 2) && ! navbar.hasClass('navbar-position-fixed')) {
              $('.js--smart-scroll').removeClass('scrolled-up navbar-position-fixed')
                                    .addClass('scrolled-down');
            }

          }

          // On initial position remove any new classes
          if(scroll_top == 0) {
            $('.js--smart-scroll').removeClass('navbar-position-fixed');
          }

          last_scroll_top = scroll_top;
      });

  }
  // END Scrollable header with animation

  // Scroll 1px top
  $( document ).ready(function() {
    if($(window).scrollTop() > $('.js--smart-scroll').outerHeight()) {
        $('.js--smart-scroll').addClass('scrolled-down');
    }
  });

  $('#elementor-tab-title-1911').on('click', function(){
    var map = $('#elementor-tab-content-1911 .map-p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  $('#elementor-tab-title-1912').on('click', function(){
    var map = $('#elementor-tab-content-1912 .map-p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  $('#elementor-tab-title-1913').on('click', function(){
    var map = $('#elementor-tab-content-1913 .map-p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  $('#elementor-tab-title-1914').on('click', function(){
    var map = $('#elementor-tab-content-1914 .map-p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  $('#elementor-tab-title-1915').on('click', function(){
    var map = $('#elementor-tab-content-1915 .map-p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  $('#elementor-tab-title-1916').on('click', function(){
    var map = $('#elementor-tab-content-1916 p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  $('#elementor-tab-title-1917').on('click', function(){
    var map = $('#elementor-tab-content-1917 p').attr('data-iframe');
    $('.contacts-map-section iframe').attr('src', map);
  });

  // $('#searchsubmit').removeClass('btn btn-primary');
  //
  // $("#searchsubmit").on("click",function(e){
	// e.preventDefault();
	// $("#searchform .form-control").toggleClass("open");
  // });

});
